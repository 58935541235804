import React from "react"
import Game from './Game';

function Games() {
  // For listing from db
  const [gameData, setGameData] = React.useState({})

  // For updating db
  const [updateID, setUpdateID] = React.useState({})

  // For triggering refresh on db update
  const [updatedData, setUpdatedData] = React.useState({})

  // LIST Side effects.
  React.useEffect(function(){
    let cacheBuster = Math.random().toString()

    var myHeaders = new Headers();
    myHeaders.append('cache-control', 'no-cache');
    
    var myInit = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(`https://systemslips.com/nrfi/api/?g=1&cache=${cacheBuster}`, myInit)
    .then(res => res.json())
    .then(data => setGameData(data))
  }, [updatedData])

  // UPDATE Side effects.
  React.useEffect(function(){

    const isUpdateArray = Array.isArray(updateID)

    if (isUpdateArray){
      const [id, results] = updateID
 
      let updateKey = id + '_' + results + '_' + Math.random().toString()
      fetch(`https://systemslips.com/nrfi/api/?u=${results}&id=${id}`, {cache: "no-cache"})
      .then(res => res.json())
      .then(setUpdatedData(prevKey => updateKey))
      
    }
  }, [updateID])

  // Update game data.
  function toggle(array){
    let newResults = 0
    const [id, results] = array
    if (results === '1'){
      newResults = 0
    } else {
      newResults = 1
    }
    //console.log('id: '+id + ' OLD results: ' + results)
    //console.log('id: '+id + ' NEW results: ' + newResults)

    let newData = [id, newResults]
    //console.log('newData')
    //console.log(newData)
    
    setUpdateID(newData)


  }
  //console.log(gameData)
  
  const games = Array.from(gameData).map(item => {
    return (
      <Game 
        key = {item.id}
        id = {item.id}
        date = {item.date}
        away = {item.away}
        home = {item.home}
        result = {item.result}
        toggle = {toggle}
      />
    )
  })
  

  return(
    <div className="container">
      {games}
    </div>
  )
}

export default Games;

