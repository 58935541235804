
import React from "react"

function Game(props) {


  return (
    <div className="row">
      <div class="col">
        {props.date}
      </div>
      <div className="col">
        {props.away} at {props.home}
      </div>
      <div className="col">
        {props.result}
      </div>
      <div className="col">
        <button onClick={() => props.toggle([props.id,props.result])} >Update</button>
      </div>
    </div>
  );
}

export default Game;
