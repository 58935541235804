
import React from "react"

function Net() {
  // Create state.
  const [netData, setNetData] = React.useState({})
  const [whip, setWhip] = React.useState(1.22)
  const [runs, setRuns] = React.useState(.49)

  function addWhip(){
    setWhip(prevWhip => Math.round((prevWhip + .01) * 1e12) / 1e12)
  }

  function subWhip(){
    if (runs > .01){
      setWhip(prevWhip => Math.round((prevWhip - .01) * 1e12) / 1e12)
    }
  }

  function addRuns(){
    setRuns(prevRuns => Math.round((prevRuns + .01) * 1e12) / 1e12)
  }

  function subRuns(){
    if (runs > .01){
      setRuns(prevRuns => Math.round((prevRuns - .01) * 1e12) / 1e12)
    }
  }
  
  // Side effects.
  React.useEffect(function(){
    //console.log('net list');

    fetch(`https://systemslips.com/nrfi/api/?w=${whip}&r=${runs}`)
    .then(res => res.json())
    .then(data => setNetData(data))
  }, [whip,runs])

//{JSON.stringify(netData, null, 2)}

  return (
    <div className="container text-center">

      <div className="row" id="units">
        <div className="col">
          <h1>{netData.units} Units</h1>
          <h2>{netData.nrfi_count}-{netData.yrfi_count} ({netData.net}%)</h2>
        </div>
      </div>
      <div className="row" id="info">
        <div class="col">
          <p>Algorithm is currently set to look at less than 1.22 WHIP and less than .49 Runs</p>
          <p>Change values to see affects on net profits.</p>
        </div>
      </div>
      <div className="row">
        <div className=""><h4>1st Inn. Whip AVG</h4></div>

        <div className=" buttons col">
        
          <button className="btn btn-danger" onClick={subWhip}>-</button> 
        </div>

        <div className=" buttons col">
          {whip} 
        </div>

        <div className=" buttons col">
          <button className="btn btn-success" onClick={addWhip}>+</button>
        </div>
      </div>

   

      <div className="row">
        <div className=""><h4>1st Inn. Runs AVG</h4></div>

        <div className=" buttons col"> 
          <button className="btn btn-danger" onClick={subRuns}>-</button> 
        </div>
        <div className=" buttons col"> 
          {runs} 
        </div>

        <div className=" buttons col"> 
          <button className="btn btn-success" onClick={addRuns}>+</button>
        </div>

      </div>
    </div>
  );
}

export default Net;
