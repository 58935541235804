import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Games from './components/Games';
import Net from './components/Net';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function App() {
  return (
    <Router>
      <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">NRFI</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              
              <Nav.Link href="/data">Data</Nav.Link>
            
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route exact path="/" element={<Net/>}/>
          <Route exact path="/games" element={<Games/>}/>
          <Route exact path="/data" element={<Net/>}/>
        </Routes>
      </div>
    </Router>
  );
}

